import React, { useEffect } from 'react';
import { useLanguage } from '../../LanguageContext'; // Adjust the import path if necessary
import './showcase.css';
import insideCandleGif from '../../assets/candle-Design.gif';

import Aos from 'aos';
import 'aos/dist/aos.css';

const Showcase = () => {
  const { language } = useLanguage();

  const content = {
    en: {
      title: '[the candle]',
      text: [
        'Handpoured',
        '100% Vegan & Organic Wax',
        '260 g | 9.17 oz',
        'Crystals Forever',
        'Reusable Tin Jar & Lid',
      ],
    },
    tr: {
      title: '[mum]',
      text: [
        'El Yapımı,',
        '%100 Doğal Mum, Vegan',
        '260 g | 9.17 oz',
        'Sonsuza Kadar Kristaller',
        'Tekrar Kullanılabilir Teneke Kavanoz ve Kapak',
      ],
    },
  };

  const { title, text } = content[language];

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="showcase-container">
      <div className="showcase-content">
        <div className="text-content">
          <h2 data-aos='fade-up'>{title}</h2>
          {text.map((line, index) => (
            <p data-aos='fade-up' key={index}>{line}</p>
          ))}
        </div>
        <div className="image-content">
          <img src={insideCandleGif} alt="Inside Candle GIF" className="inside-image" />
        </div>
      </div>
    </div>
  );
};

export default Showcase;
